<template>
  <div class="container-fluid" style="padding: 0px;">
    <div class="row">
      <div class="navbar fixed-top d-block d-md-none bg-dark" >
        <div class="d-flex justify-content-start" style="color: white; font-family: SaloonGirl; padding-top:20px; padding-left:15px;">
          <h1 style="font-size:48px;">Cowboy Co. Jewelry</h1><h3>Since 2016</h3>
        </div>
      </div>
      <div class="col-md-12 p-0">
        <div class="position-relative">
          <div class="d-block d-md-none bg-dark" >
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
            <br>
          </div>
          <img src="@/assets/IMG_2063.jpeg" class="img-fluid" />
          <div class="position-absolute top-50 translate-middle d-none d-md-block"
            style="padding-left: 50vw; padding-top: 20vw; color: white;">
            <h1 style="width: 75%; margin: 0px; font-size: 8vw; font-family: SaloonGirl;">Cowboy Co. Jewelry</h1>
            <h3 class="font-size-md" style="font-size: 3vw; font-family: SaloonGirl;">- Since 2016 -</h3>
          </div>
          <div class="position-absolute top-100 start-100 translate-middle" style="margin-left: -8vw; margin-top: -8vw;">
            <img src="@/assets/cowboy_hat.png" class="img-fluid" style="scale: 70%;" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 ">
        <div class="position-relative top-50 start-50 translate-middle" style="padding: 8vw;">
          <p class="fs-1">
            We are a couple of musicians, artists, and motorcyclers, married for almost fifteen years now. We have a love
            of
            form and uniqueness. 
          </p>  
          <br>
          <p class="fs-1">  
            We like to make things that people enjoy to wear and take pride in owning.
          </p>
          <br>
          <p class="fs-1">
            We work on commission only. Please contact us if you're interested in collaborating on a piece of wearable
            art.
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <img class="img-fluid" src="@/assets/us.png" style="padding: 60px; width: 100%;" />
      </div>
    </div>
    <div class="row border border-dark border-3">
      <div class="col-md-3 p-0">
        <img class="img-fluid " src="@/assets/hex_ring.jpg" />
      </div>
      <div class="col-md-3 p-0 bg-dark">
        <div class="d-flex align-items-center justify-content-center h-100">
          <div class="image-wrapper">
            <img class="img-fluid" src="@/assets/skull_background_raw.webp" />
          </div>
        </div>

      </div>
      <div class="col-md-3 p-0">
        <img class="img-fluid" src="@/assets/poppy.jpg" />
      </div>

      <div class="col-md-3 p-0 bg-dark">
        <div class="d-flex align-items-center justify-content-center h-100">
          <div class="image-wrapper">
            <img class="img-fluid" src="@/assets/bbb.jpg" />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 offset-md-3" style="font-size: 32px;">
        Please contact us via <a href="https://www.instagram.com/cowboyco/" target=”_blank”>Instagram</a>
        <a href="https://www.instagram.com/cowboyco/" target=”_blank”><img src="@/assets/Instagram_icon.png.webp"
            style="padding: 30px; width: 100px;" /></a>
      </div>
    </div>
  </div>
</template>

<script>
</script>

<style>
body {
  overflow-x: hidden;
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
  height: 100vh;
  margin: 0px;
  padding: 0px
}

.p {
  font-size: 64px;
}

@font-face {
  font-family: "SaloonGirl";
  src: local("SaloonGirl"),
    url(./fonts/SaloonGirl.ttf) format("truetype");
}
</style>
